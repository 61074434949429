<template>
  <v-layout class="rounded rounded-md">
    <MenuSystem v-if="logged" class="h-100" />
    <v-main class="d-flex" style="min-height: 300px; max-width: 100%;">
      <router-view />
    </v-main>
    <v-dialog v-model="dialogChangePassword" 
      max-width="600"
      @click:outside="hideDialogChangePassword"
    >
      <change-password/>
    </v-dialog>
    <v-snackbar
      v-model="updateAvailable"
      bottom
      elevation="24"
      color="info"
      :timeout="-1"
    >
      Uma nova versão do sistema está disponível.
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="reloadApp">
          Atualizar
        </v-btn>
      </template>
    </v-snackbar>
  </v-layout>
</template>

<script>
import { ref, onMounted } from "vue";
import { useTheme } from "vuetify";
import { useStore } from "vuex";
import { defineComponent } from "vue";
import MenuSystem from "./components/menu/MenuSystem.vue";
import { computed } from "vue";
import './config/app.scss';
import validationRules from "@/utils/rules";
import ChangePassword from "./components/password/ChangePassword.vue";

export default defineComponent({
  components: {
    ChangePassword,
    MenuSystem,
  },
  setup() {
    const store = useStore();
    const theme = useTheme();
    const darkMode = ref(true);
    const formValid = ref(false);
    const toggleTheme = () => {
      theme.global.name.value = darkMode.value ? "dark" : "light";

    };
    const logged = computed(() => store?.getters["auth/isLogged"]);
    const changePasswordData = computed(() => store.state.auth.changePasswordData);
    const dialogChangePassword = computed(() => store.state.auth.showDialogChangePassword);

    const hideDialogChangePassword = () => {
      store.commit("auth/SHOW_DIALOG_CHANGE_PASSWORD", { show: false });
    }

    const submit = async (event) => {
      let eventValidation = await event;
      if(eventValidation.valid) {
        store.dispatch("auth/changePassword");
      }
    }

    const updateAvailable = ref(false); // Flag para notificar o usuário
    let registration = null; // Guarda o registro do Service Worker

    const onServiceWorkerUpdated = (event) => {
      updateAvailable.value = true; // Atualiza a flag para mostrar o banner/modal
      registration = event.detail; // Guarda a referência para o registro do SW
    };

    onMounted(() => {
      // Escuta o evento 'swUpdated' disparado pelo Service Worker
      document.addEventListener('swUpdated', onServiceWorkerUpdated, { once: true });
    });

    const reloadApp = () => {
      if (registration && registration.waiting) {
        updateAvailable.value = false;
        registration.waiting.postMessage({ type: 'SKIP_WAITING' });
        registration.waiting.addEventListener('statechange', (event) => {
          const target = event.target;

          if (target.state === 'activated') {
            console.log('Novo Service Worker ativado, recarregando a página...');
            window.location.reload(); 
          }
        });
      }
    };

    return {
      submit,
      changePasswordData,
      formValid,
      logged,
      toggleTheme,
      hideDialogChangePassword,
      dialogChangePassword,
      validationRules,
      updateAvailable,
      reloadApp,
    };
  },
});
</script>


<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 15px;
  padding-bottom: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
