import { ActionContext } from 'vuex';
import axiosAuth from '@/config/axios';
import { API_URL } from '@/config/api';
import { ClientServices } from '../types/client_services';
import { useNotification } from '@/composables/useNotification';
import { FormMode, defaultClientServices } from '../consts';
import { ClientServicesState } from '../types/state';
import { IRootState } from '@/types/state';


const { notifySuccess, notifyError } = useNotification();

const state: () => ClientServicesState = () => ({
  clientServices: [],
  deleteDialog: false,
  formMode: FormMode.Add,
  isCreateDialogOpen: false,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  selectedClientService: defaultClientServices,

});


const mutations = {

  SET_CREATE_DIALOG_OPEN(state: ClientServicesState, payload: { isOpen: boolean }) {
    state.isCreateDialogOpen = payload.isOpen;
  },
  SET_SELECTED_CLIENT(state: ClientServicesState, payload: { clientService: ClientServices }) {
    state.selectedClientService = Object.assign({}, payload.clientService);
  },
  SET_FORM_MODE(state: ClientServicesState, payload: { mode: FormMode }) {
    state.formMode = payload.mode;
  },
  SET_CLIENT_SERVICES(state: ClientServicesState, clientServices: ClientServices[]) {
    state.clientServices = clientServices;
  },
  SET_TOTAL_ITEMS(state: ClientServicesState, payload: { total: number }) {
    state.pagination.total = payload.total;
  },
  SET_DELETE_DIALOG(state: ClientServicesState, payload: { show: boolean }) {
    state.deleteDialog = payload.show;
  },


};

const actions = {
  openCreateDialog({ commit }: ActionContext<ClientServicesState, IRootState>) {
    commit('SET_FORM_MODE', { mode: FormMode.Add });
    commit('SET_SELECTED_CLIENT', { clientService: defaultClientServices });
    commit('SET_CREATE_DIALOG_OPEN', { isOpen: true });
  },
  closeCreateDialog({ commit }: ActionContext<ClientServicesState, IRootState>) {
    commit('SET_FORM_MODE', { mode: FormMode.View });
    commit('SET_SELECTED_CLIENT', { clientService: defaultClientServices });
    commit('SET_CREATE_DIALOG_OPEN', { isOpen: false });
  },
  openEditDialog({ commit }: ActionContext<ClientServicesState, IRootState>, payload: { clientService: ClientServices }) {
    const clientService = Object.assign({}, payload.clientService);
    clientService.firstDate = new Date(clientService.firstDate + 'T12:00:00');
    console.log('payload.clientService.firstDate:', payload.clientService.firstDate);
    commit('SET_FORM_MODE', { mode: FormMode.Edit });
    commit('SET_SELECTED_CLIENT', { clientService });
    commit('SET_CREATE_DIALOG_OPEN', { isOpen: true });
  },
  openDeleteDialog({ commit }: ActionContext<ClientServicesState, IRootState>, payload: { clientService: ClientServices }) {
    commit('SET_SELECTED_CLIENT', { clientService: payload.clientService });
    commit('SET_DELETE_DIALOG', { show: true });
  },
  async getClientServices({ commit }: ActionContext<ClientServicesState, IRootState>, payload: { clientId: string }) {
    try {
      const response = await axiosAuth.get<ClientServices[]>(`${API_URL}/client_services/${payload.clientId}`);
      commit('SET_CLIENT_SERVICES', response.data);

    } catch (error) {
      notifyError('Erro ao buscar serviços');
      console.error('Erro ao buscar serviços:', error);
    }
  },
  async updateService({ dispatch, state, rootGetters }: ActionContext<ClientServicesState, IRootState>) {
    const selectedClientService = state.selectedClientService;

    try {
      await axiosAuth.put(`${API_URL}/client_services/${selectedClientService.id}`, selectedClientService);
      notifySuccess('Serviço atualizado com sucesso');
      dispatch('getClientServices', { clientId: selectedClientService.clientId });
      dispatch('closeCreateDialog')
    } catch (error: any) {
      if(error?.response?.data?.message){
        console.error('Erro ao criar serviço para o cliente:', error);
        notifyError(error.response.data.message);
        return
      }
      console.error('Erro ao atualizar serviço para o cliente:', error);
      notifyError('Erro');
    }
  },
  async saveService({ dispatch, state, rootGetters }: ActionContext<ClientServicesState, IRootState>) {
    const { client,service,id, ...clientServiceToSave } = state.selectedClientService;
    const currentClientId = rootGetters['clients/getCurrentClientId'];
    clientServiceToSave.clientId = currentClientId;

    try {
      await axiosAuth.post(`${API_URL}/client_services`, clientServiceToSave);
      notifySuccess('Serviço criado com sucesso');
      dispatch('getClientServices', { clientId: currentClientId });
      dispatch('closeCreateDialog')
    } catch (error) {
      console.error('Erro ao criar serviço para o cliente:', error);
      notifyError('Erro');
    }
  },
  async deleteClientService({ commit, dispatch, state, rootGetters }: ActionContext<ClientServicesState, IRootState>) {
    const selectedClientService = state.selectedClientService;
    try {
      await axiosAuth.delete(`${API_URL}/client_services/${selectedClientService.id}`);
      notifySuccess('Serviço excluído com sucesso');
      dispatch('getClientServices', { clientId: selectedClientService.clientId });
      commit('SET_DELETE_DIALOG', { show: false });
    } catch (error: any) {
      if(error?.response?.data?.message){
        console.error('Erro ao criar serviço para o cliente:', error);
        notifyError(error.response.data.message);
        return
      }
      console.error('Erro ao atualizar serviço para o cliente:', error);
      notifyError('Erro');
    }
  }
};



export default {
  namespaced: true,
  state,
  mutations,
  actions
};
