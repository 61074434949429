import { ActionContext } from 'vuex';
import axiosAuth from '@/config/axios';
import { API_URL } from '@/config/api';
import { Client, IFilterClient } from '../types/client';
import { useNotification } from '@/composables/useNotification';
import { FormMode, defaultClient, defaultFilterClient } from '../consts';
import { ClientState } from '../types/state';
import { ClientServices } from '@/modules/client_services/types/client_services';
import { ClientAddresses } from '@/modules/client_addresses/types/client_addresses';
import { DefaultResponse, DefaultResponseItem } from '@/types/response';
import { IRootState } from '@/types/state';
import { ClientResponsible } from '@/modules/responsibles/types/responsibles';
import { ClientComplaints } from '../../client_complaints/types/complaints';
import { createClient } from '@supabase/supabase-js';


const { notifySuccess, notifyError } = useNotification();

const state: () => ClientState = () => ({
  addresses: [],
  clients: [],
  clientsByEmployee: [],
  clientResponsible: null,
  services: [],
  currentClientId: '',
  filteredClients: [],
  filterClient: Object.assign({}, defaultFilterClient),
  formMode: FormMode.Add,
  isCreateDialogOpen: false,
  isFilterDialogOpen: false,
  pagination: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  paginationServices: {
    page: 1,
    itemsPerPage: 10,
    total: 0,
  },
  showDeleteDialog: false,
  selectedClient: defaultClient,
  selectedClientEmployees: [],
});

const getters = {
  getCurrentClientId: (state: ClientState) => {
    return state.currentClientId;
  },
}

const mutations = {
  SET_CURRENT_CLIENT_ID(state: ClientState, payload: { clientId: string }) {
    state.currentClientId = payload.clientId;
  },
  SET_CLIENTS(state: ClientState, payload: { clients: Client[] }) {
    state.clients = payload.clients;
    state.filteredClients = payload.clients;
  },
  SET_FILTER_CLIENT(state: ClientState, filterClient: IFilterClient) {
    state.filterClient = filterClient;
  },
  SET_FILTERED_CLIENTS(state: ClientState, filteredClients: Client[]) {
    state.filteredClients = filteredClients;
  },
  SET_CREATE_DIALOG_OPEN(state: ClientState, isOpen: boolean) {
    state.isCreateDialogOpen = isOpen;
  },
  SET_FILTER_DIALOG_OPEN(state: ClientState, isOpen: boolean) {
    state.isFilterDialogOpen = isOpen;
  },
  SET_SELECTED_CLIENT(state: ClientState, client: Client) {
    state.selectedClient = Object.assign({}, client);
  },
  SET_FORM_MODE(state: ClientState, mode: FormMode) {
    state.formMode = mode;
  },
  SET_ADDRESSES(state: ClientState, addresses: ClientAddresses[]) {
    state.addresses = addresses;
  },
  SET_SERVICES(state: ClientState, addresses: ClientServices[]) {
    state.services = addresses;
  },
  SET_TOTAL_ITEMS(state: ClientState, payload: { total: number }) {
    state.pagination.total = payload.total;
  },
  SET_CLIENT_RESPONSIBLE(state: ClientState, clientResponsible: ClientResponsible) {
    state.clientResponsible = clientResponsible;
  },
  SET_CLIENTS_BY_EMPLOYEE(state: ClientState, payload: { clientsByEmployee: Client[] }) {
    state.clientsByEmployee = payload.clientsByEmployee;
  },  
  SHOW_DELETE_DIALOG(state: ClientState, payload: { show: boolean }) {
    state.showDeleteDialog = payload.show;
  },
  RESET_CLIENT_FILTER(state: ClientState) {
    state.filterClient = Object.assign({}, defaultFilterClient);
  },

};

const actions = {
  clearFilter({ commit }: ActionContext<ClientState, IRootState>) {
    commit('SET_FILTER_CLIENT', Object.assign({}, defaultFilterClient));
  },
  async fetchClientsById({ commit }: ActionContext<ClientState, IRootState>, payload: { clientId: string }) {
    try {
      const response = await axiosAuth.get<Client>(`${API_URL}/clients/${payload.clientId}`);
      commit('SET_SELECTED_CLIENT', response.data);
    } catch (error) {
      notifyError('Erro ao buscar cliente');
      console.error('Erro ao buscar cliente:', error);
    }
  },
  async fetchClients({ commit, state }: ActionContext<ClientState, IRootState>) {
    try {
      const params: any = {};

      for (const key in state.filterClient) {
        const value = state.filterClient[key as keyof typeof state.filterClient];
        if (value || value === 0) {
          params[key] = value;
        }
      }
      params.page = state.pagination.page;
      params.itemsPerPage = state.pagination.itemsPerPage;
      const response = await axiosAuth.get<DefaultResponse<Client>>(`${API_URL}/clients`,
        {
          params
        }
      );
      commit('SET_TOTAL_ITEMS', { total: response.data.total });
      commit('SET_CLIENTS', { clients: response.data.data });
    } catch (error) {
      notifyError('Erro ao buscar clientes');
      console.error('Erro ao buscar clientes:', error);
    }
  },
  async createClient({ dispatch, state }: ActionContext<ClientState, IRootState>) {
    const { ...clientToSave } = state.selectedClient;
    delete clientToSave.id;
    delete clientToSave.createdAt;
    try {
      await axiosAuth.post(`${API_URL}/clients`, clientToSave);
      notifySuccess('Cliente criado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      console.error('Erro ao criar cliente:', error);
      notifyError('Erro');
    }
  },
  async updateClient({ dispatch, state }: ActionContext<ClientState, IRootState>) {
    const { id, ...clientToUpdate } = state.selectedClient;
    try {
      await axiosAuth.put(`${API_URL}/clients/${id}`, clientToUpdate);
      notifySuccess('Cliente atualizado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      notifyError('Erro');
      console.error('Erro ao atualizar cliente:', error);
    }
  },
  async changeStatusClient({ dispatch }: ActionContext<ClientState, IRootState>, id: string) {
    try {
      await axiosAuth.put(`${API_URL}/clients/${id}/status`);
      notifySuccess('Status do cliente alterado com sucesso');
      dispatch('fetchClients');
    } catch (error) {
      notifyError('Erro');
      console.error('Erro ao alterar status do cliente:', error);
    }
  },
  async deleteClient({ commit, dispatch, state }: ActionContext<ClientState, IRootState>) {
    try {
      await axiosAuth.delete(`${API_URL}/clients/${state.selectedClient.id}`);
      notifySuccess('Cliente deletado com sucesso');
      dispatch('fetchClients');
      commit('SHOW_DELETE_DIALOG', { show: false });
    } catch (error) {
      notifyError('Erro');
      console.error('Erro ao deletar cliente:', error);
    }
  },
  openDeleteDialog({ commit }: ActionContext<ClientState, IRootState>, payload: { client: Client }) {
      commit('SHOW_DELETE_DIALOG', { show: true });
      commit('SET_SELECTED_CLIENT', payload.client);
  },
  openFilterDialog({ commit }: ActionContext<ClientState, IRootState>) {
    commit('SET_FILTER_DIALOG_OPEN', true);

  },
  closeFilterDialog({ commit }: ActionContext<ClientState, IRootState>) {
    commit('SET_FILTER_DIALOG_OPEN', false);
  },
  async fetchClientResponsible({ commit }: ActionContext<ClientState, IRootState>, payload: { clientId: string }) {
    try { 
      console.log('fetchClientResponsible', payload.clientId)
      const result = await axiosAuth.get<DefaultResponseItem<ClientResponsible>>(`${API_URL}/responsibles/client_active/${payload.clientId}`);
      commit('SET_CLIENT_RESPONSIBLE', result.data.data);
    } catch(error) {
      console.error('Erro ao buscar responsável do cliente:', error);
      notifyError('Erro ao buscar responsável do cliente');
    }
  },
  async fetchClientsByEmployee({ commit }: ActionContext<ClientState, IRootState>, payload: { employeeId: string }) {
    try {
      const url = `${API_URL}/client_employee/clients/by-employee/${payload.employeeId}`;
      const response = await axiosAuth.get<DefaultResponse<Client>>(`${url}`);
      
      commit('SET_CLIENTS_BY_EMPLOYEE', { clientsByEmployee: response.data.data });
    } catch (error) {
      notifyError('Erro ao buscar os dados');
      console.error('Erro ao buscar os dados:', error);
    }
  },
  openCreateDialog({ commit }: ActionContext<ClientState, IRootState>) {
    console.log('openCreateDialog')
    commit('SET_FORM_MODE', FormMode.Add);
    commit('SET_SELECTED_CLIENT', defaultClient);
    commit('SET_CREATE_DIALOG_OPEN', true);
  },
  openEditDialog({ commit }: ActionContext<ClientState, IRootState>, client: Client) {
    commit('SET_FORM_MODE', FormMode.Edit);
    commit('SET_SELECTED_CLIENT', client);
    commit('SET_CREATE_DIALOG_OPEN', true);
  },
  async openScreenDetails({ dispatch, commit }: ActionContext<ClientState, IRootState>, payload: { clientId: string }) {
    try {
      commit('SET_CURRENT_CLIENT_ID', { clientId: payload.clientId })

      dispatch('fetchClientsById', { clientId: payload.clientId });
      dispatch('client_addresses/getClientAddresses', { clientId: payload.clientId }, { root: true });
      dispatch('client_services/getClientServices', { clientId: payload.clientId }, { root: true });
      dispatch('services/fetchServices', {}, { root: true });
      dispatch('client_employee/fetchClientsEmployees', { clientId: payload.clientId }, { root: true });
    } catch (error) {
      notifyError('Erro ao buscar informações do cliente');
      console.error('Erro ao buscar informações do cliente:', error);
    }
  },




  openViewDialog({ commit }: ActionContext<ClientState, IRootState>, client: Client) {
    commit('SET_FORM_MODE', FormMode.View);
    commit('SET_SELECTED_CLIENT', client);
    commit('SET_CREATE_DIALOG_OPEN', true);
  },
  closeCreateDialog({ commit }: ActionContext<ClientState, IRootState>) {
    console.log('closeCreateDialog')
    commit('SET_CREATE_DIALOG_OPEN', false);
  },
};



export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
};
