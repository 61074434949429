import { IRootState } from "@/types/state";
import { ClientComplaintsState } from "../types/state";
import { ActionContext } from "vuex";
import axiosAuth from "@/config/axios";
import { DefaultResponse } from "@/types/response";
import { ClientComplaints } from "../types/complaints";
import { API_URL } from "@/config/api";
import { useNotification } from "@/composables/useNotification";
import { defaultClientComplaint } from "../consts";

const { notifySuccess, notifyError } = useNotification();

const state: () => ClientComplaintsState = () => ({
    complaints: [],
    selectedClientComplaint: {
        id: '',
        clientId: '',
        type: 0,
        description: ''
    },
    showDeleteDialog: false,
    showFormDialog: false,
    pagination: {
        page: 1,
        itemsPerPage: 10,
        total: 0
    }

});

const getters = {};

const mutations = {
    SET_COMPLAINTS(state: ClientComplaintsState, complaints: ClientComplaints[]) {
        state.complaints = complaints;
        console.log(state.complaints);
    },
    SET_SELECTED_COMPLAINT(state: ClientComplaintsState, complaint: ClientComplaints) {
        state.selectedClientComplaint = complaint;
    },

    SET_PAGINATION(state: ClientComplaintsState, pagination: any) {
        state.pagination = pagination;
    },
    SET_TOTAL_ITEMS(state: ClientComplaintsState, payload: { total: number }) {
        state.pagination.total = payload.total;
    },
    SHOW_DELETE_DIALOG(state: ClientComplaintsState, payload: { show: boolean }) {
        state.showDeleteDialog = payload.show;
    },
    SHOW_FORM_DIALOG(state: ClientComplaintsState, payload: { show: boolean }) {
        state.showFormDialog = payload.show;
    }
};

const actions = {
    openFormDialog({ commit }: ActionContext<ClientComplaintsState, IRootState>) {
        commit('SHOW_FORM_DIALOG', { show: true });
        commit('SET_SELECTED_COMPLAINT', Object.assign({}, defaultClientComplaint));
    },
    closeFormDialog({ commit }: ActionContext<ClientComplaintsState, IRootState>) {
        commit('SHOW_FORM_DIALOG', { show: false });
        commit('SET_SELECTED_COMPLAINT', Object.assign({}, defaultClientComplaint));
    },

    async saveComplaint({ commit, state, dispatch, rootGetters }: ActionContext<ClientComplaintsState, IRootState>) {
        try {
            console.log('saveComplaint', state.selectedClientComplaint);
            const currentClientId = rootGetters['clients/getCurrentClientId'];
            state.selectedClientComplaint.clientId = currentClientId;
            const { ...complaintToSave } = state.selectedClientComplaint;
            delete complaintToSave.id;
            await axiosAuth.post(`${API_URL}/client_complaints`, complaintToSave);
            notifySuccess('Salvo com sucesso');
            dispatch('closeFormDialog',);
            await dispatch('fetchClientComplaints', { clientId: currentClientId });
        } catch (error) {
            notifyError('Erro ao salvar');
            console.error('Erro ao salvar:', error);

        }

    },

    async fetchClientComplaints({ commit, state }: ActionContext<ClientComplaintsState, IRootState>, payload: { clientId: string }) {
        try {
            const params: any = {};
            params.page = state.pagination.page;
            params.itemsPerPage = state.pagination.itemsPerPage;
            const response = await axiosAuth.get<DefaultResponse<ClientComplaints>>(`${API_URL}/client_complaints/${payload.clientId}`,
                {
                    params
                }
            );
            commit('SET_TOTAL_ITEMS', { total: response.data.total });
            commit('SET_COMPLAINTS', response.data.data);
        } catch (error) {
            notifyError('Erro ao buscar');
            console.error('Erro ao buscar:', error);
        }
    },

    async deleteComplaint({ commit, state, dispatch, rootGetters }: ActionContext<ClientComplaintsState, IRootState>) {
        try {
            console.log('deleteComplaint', state.selectedClientComplaint);
            const currentClientId = rootGetters['clients/getCurrentClientId'];
            await axiosAuth.delete(`${API_URL}/client_complaints/${state.selectedClientComplaint.id}`);
            notifySuccess('Deletado com sucesso');
            dispatch('closeDeleteDialog');
            dispatch('fetchClientComplaints', {clientId: currentClientId});
        } catch (error) {
            notifyError('Erro ao deletar');
            console.error('Erro ao deletar:', error);
        }
    },
    openDeleteDialog({ commit }: ActionContext<ClientComplaintsState, IRootState>, payload: { clientComplaint: ClientComplaints }) {
        commit('SHOW_DELETE_DIALOG', { show: true });
        commit('SET_SELECTED_COMPLAINT', payload.clientComplaint);
    },
    closeDeleteDialog({ commit }: ActionContext<ClientComplaintsState, IRootState>) {
        commit('SHOW_DELETE_DIALOG', { show: false });
        commit('SET_SELECTED_COMPLAINT', Object.assign({}, defaultClientComplaint));
    },
};


export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
};